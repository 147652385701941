import type { FetchError } from 'ofetch'

export function useEmailFlow() {
  const nuxtApp = useNuxtApp()
  const siteConfig = useSiteConfig()

  const confirmEmailJwt = async (
    confirmationToken: string,
    userGuid: string
  ) => {
    return await nuxtApp.$api.user.confirmEmailJwt(userGuid, confirmationToken)
  }

  const sendConfirmationEmailJwt = async (
    returnUrl: string,
    email: string,
    paperIds?: string
  ) => {
    return await nuxtApp.$api.user.sendConfirmationEmailJwt(
      `${siteConfig.domainfull}`,
      email,
      returnUrl,
      paperIds
    )
  }

  const sendAccessGrantedReceiptEmail = async (
    userId: number,
    receiptPapers: string
  ) => {
    return await nuxtApp.$api.user.sendAccessGrantedReceiptEmail(
      userId,
      receiptPapers
    )
  }

  const changePasswordJwt = async (
    userGuid: string,
    newPassword: string,
    passKey: string
  ) => {
    const userStore = useUserStore()

    const result = await userStore.loginPasswordEdit(
      userGuid,
      newPassword,
      passKey
    )

    if (result.status === 'success') {
      return createSuccessResponse()
    }

    return createErrorResponse('could not change password Jwt', result.error)
  }

  const sendPasswordResetEmail = async (email: string) => {
    return await nuxtApp.$api.account.sendPasswordResetEmailJwt(email)
  }

  return {
    confirmEmailJwt,
    sendConfirmationEmailJwt,
    sendAccessGrantedReceiptEmail,
    changePasswordJwt,
    sendPasswordResetEmail,
  }
}
